<template>
  <div class="causes">
    <div class="causes__wrapper container">
      <p class="causes__title">Причины аварийного вскрытия</p>
      <p class="causes__discription">
        Ситуация, когда требуется срочное вскрытие двери, может случиться с
        каждым. Не важно, какой запорной системой вы пользуетесь, стандартной
        или сложной замковой системой, открыть дверь без профессиональных знаний
        и уверенных навыков работы с отмычками у вас не получится. Вот основные
        причины, которые приводят к вызову мастера:
      </p>
      <div class="causes__cards">
        <div class="causes-card" v-for="(cause, index) in causes" :key="index">
          <div class="causes-card__item">
            <p class="causes-card__text">
              {{ cause.text }}
            </p>
            <div
              class="causes-card__sircle"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../assets/images/ico/causes-card/' +
                    cause.ico +
                    '.svg') +
                  ')',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      causes: [
        {
          text: "Кража или потеря ключа. Одна из самых распространенных предпосылок для взлома замка",
          ico: "1",
        },
        {
          text: "Поломка ключа. При длительном использовании, особенно если замочный блок открывается с некоторым усилием, ключ может сломаться и застрять в скважине",
          ico: "2",
        },
        {
          text: "Случайность. Если дверь в квартиру или дом оборудована автоматическим замком, он может захлопнуться по  случайности, когда вы просто вышли к мусоропроводу",
          ico: "3",
        },
        {
          text: "Заклинивание механизма. Он может выйти из строя по многим причинам. Если вышел срок его эксплуатации, если в него попала влага или посторонний предмет,если сильно захлопывать дверь и т. д.",
          ico: "4",
        },
        {
          text: "В большинстве случаев, которые требуют срочной помощи, потребуется замена устройства на новое. Специалисты нашей компании могут не только аккуратно открыть любое устройство, но могут сразу же заменить его на новое.",
          ico: "5",
        },
      ],
    };
  },
};
</script>

<style src="./causes.less" lang="less"></style>
