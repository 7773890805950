<template>
  <header
    class="header"
    :style="this.isActive ? 'border-radius: 0 0 25px 25px' : 'border-radius: 0'"
    ref="header"
    v-show="show"
  >
    <div class="header__container container">
      <div class="header__logo">
        <img
          src="@/assets/images/img/logo.png"
          alt="logo"
          class="header__logo-img"
        />
      </div>
      <div class="header__menu menu">
        <div class="menu__burger" @click="openMenu()">
          <div class="menu-burger" :class="{ active: !isActive }"></div>
        </div>
        <div class="header__orientation">
          <nav class="menu__body" :class="{ active: !isActive }" ref="color">
            <ul class="menu__list">
              <li>
                <a data-goto=".page__section_1" class="menu__link" href="#"
                  >Главная</a
                >
              </li>
              <li>
                <a data-goto=".page__section_2" class="menu__link" href="#"
                  >Услуги</a
                >
              </li>
              <li>
                <a data-goto=".page__section_3" class="menu__link" href="#"
                  >О компании</a
                >
              </li>
              <li>
                <a data-goto=".page__section_4" class="menu__link" href="#"
                  >Отзывы</a
                >
              </li>
              <li>
                <a data-goto=".page__section_6" class="menu__link" href="#"
                  >Гарантии</a
                >
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "Header",
  props: {
    scroll: Boolean,
  },
  data() {
    return {
      isActive: true,
      lastScroll: 0,
      show: true,
    };
  },
  methods: {
    openMenu() {
      if (this.isActive) {
        this.isActive = false;
        document.body.classList.add("lock");
      } else {
        this.isActive = true;
        document.body.classList.remove("lock");
      }
    },
    onMenuLinkClick(e) {
      const menuLink = e.target;

      if (
        menuLink.dataset.goto &&
        document.querySelector(menuLink.dataset.goto)
      ) {
        const gotoBlock = document.querySelector(menuLink.dataset.goto);

        const gotoBlockValue =
          gotoBlock.getBoundingClientRect().top +
          pageYOffset -
          document.querySelector("header").offsetHeight;

        window.scrollTo({
          top: gotoBlockValue,
          behavior: "smooth",
        });

        this.isActive = true;
        document.body.classList.remove("lock");

        e.preventDefault();
      }
    },
  },
  mounted() {
    const menuLinks = document.querySelectorAll(".menu__link[data-goto]");
    if (menuLinks.length > 0) {
      menuLinks.forEach((menuLink) => {
        menuLink.addEventListener("click", this.onMenuLinkClick);
      });
    }
    gsap.from(".header__logo", {
      ease: "Bounse",
      duration: 1,
      scale: 0.9,
      yoyo: true,
      repeat: -1,
    });
  },
};
</script>

<style lang="less" src="./header.less"></style>
