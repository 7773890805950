<template>
  <div class="spheres page__section_5">
    <div class="spheres__wrapper container">
      <div class="spheres__cards">
        <div
          class="spheres-card"
          v-for="(sphere, index) in spheres"
          :key="index"
        >
          <div class="spheres-card__item">
            <div class="spheres-card__logo">
              <img
                :src="require(`@/assets/images/ico/${sphere.ico + '.svg'}`)"
                class="spheres-card__ico"
              />
            </div>
            <p class="spheres-card__name">{{ sphere.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spheres: [
        {
          title: "ВСКРЫТИЕ ДВЕРЕЙ",
          ico: "ico-door",
        },
        {
          title: "ВСКРЫТИЕ АВТО",
          ico: "ico-car",
        },
        {
          title: "Вскр. сейфов",
          ico: "ico-safe",
        },
        {
          title: "Вскрытие замков",
          ico: "ico-zamok",
        },
        {
          title: "Ремонт замков",
          ico: "ico-zamok2",
        },
      ],
    };
  },
};
</script>

<style lang="less" src="./spheres.less"></style>
