<template>
  <Header />
  <Preview />
  <Spheres />
  <Services />
  <About />
  <Causes />
  <Reviews />
  <Benefits />
  <Footer />
</template>

<script>
import Header from "@/components/header/header.vue";
import Preview from "@/components/preview/preview.vue";
import Spheres from "@/components/spheres/spheres.vue";
import Services from "@/components/services/services.vue";
import About from "@/components/about/about.vue";
import Causes from "@/components/causes/causes.vue";
import Reviews from "@/components/reviews/reviews.vue";
import Benefits from "@/components/benefits/benefits.vue";
import Footer from "@/components/footer/footer.vue";

export default {
  components: {
    Header,
    Preview,
    Spheres,
    Services,
    About,
    Causes,
    Reviews,
    Benefits,
    Footer,
  },
};
</script>

<style lang="less" src="./mainPage.less"></style>
