<template>
  <div class="about page__section_3">
    <div class="about__wrapper container">
      <p class="about__top">О компании</p>
      <div class="about__row">
        <img
          src="@/assets/images/img/about/about2.png"
          alt=""
          class="about__img about__img_hiden"
        />
        <div class="about__content">
          <p class="about__title">Сколько мы уже работаем ?</p>
          <p class="about__text">
            «Служба-вскрытия24» – сервисная служба, основанная в 2012 году,
            которая специализируется на экстренном вскрытии замков, а также
            замене, установке и обслуживании запорных механизмов в жилых домах и
            квартирах, офисах, сейфах и воротах по всей России
          </p>
        </div>
        <img
          src="@/assets/images/img/about/about1.png"
          alt=""
          class="about__img"
        />
      </div>
      <div class="about__row">
        <img
          src="@/assets/images/img/about/about3.png"
          alt=""
          class="about__img about__img_hiden"
        />
        <div class="about__content about__content_order">
          <p class="about__title">Какие у нас маcтера ?</p>
          <p class="about__text">
            Все наши мастера обладают профессиональной слесарной подготовкой и
            знанием современных замочных механизмов. В зависимости от типа
            поломки (сломался ключ в скважине или заела личинка) мы подберем
            подходящий метод вскрытия и сообщим о стоимости работы заранее.
          </p>
        </div>
        <img
          src="@/assets/images/img/about/about4.png"
          alt=""
          class="about__img about__img_order"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style src="./about.less" lang="less"></style>
