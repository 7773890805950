<template>
  <div class="preview page__section_1">
    <div class="preview__wrapper container">
      <div class="preview__content">
        <p class="preview__title">Вскрытие замков по всей России</p>
        <p class="preview__discripton">
          Сломался ключ или замок? Звони! Аккуратное и быстрое вскрытие замков
        </p>
        <div class="preview__button">
          <a class="button" href="tel:+79951128880">Вызвать мастера</a>
        </div>
      </div>
      <div class="preview__images">
        <div class="preview__img preview__img-position1 parallax" origin="x">
          <img
            src="@/assets/images/img/door.png"
            alt=""
            class="preview__image"
            data-depth-x="0.5"
          />
        </div>
        <div class="preview__img preview__img-position2 parallax">
          <img
            src="@/assets/images/img/man.png"
            alt=""
            class="preview__image"
            data-depth-x="-0.5"
          />
        </div>
        <div class="preview__img preview__img-position3 parallax">
          <img
            src="@/assets/images/img/rug.png"
            alt=""
            class="preview__image"
            data-depth-x="-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "parallax-js";
import { gsap } from "gsap";
export default {
  methods: {
    parallaxElems() {
      const parallaxItems = document.querySelectorAll(".parallax");

      this._parallaxInstances = [];

      for (let i = 0; i < parallaxItems.length; i++) {
        this._parallaxInstances.push(new Parallax(parallaxItems[i]));
      }
    },
  },
  mounted() {
    this.parallaxElems();
    gsap.from(".button", { duration: 1.5, translateY: 20, opacity: 0 });

    gsap.from(".preview__discripton", {
      duration: 0.8,
      translateY: 50,
      opacity: 0,
    });
  },
};
</script>

<style lang="less" src="./preview.less"></style>
