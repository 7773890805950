<template>
  <div class="footer">
    <div class="footer__wrapper container">
      <div class="footer__logo">
        <img
          src="@/assets/images/img/logo.png"
          alt="logo"
          class="footer__logo-img"
        />
      </div>
      <div class="footer__number">
        <p class="footer__title">Звоните нам</p>
        <a class="footer__text" href="tel:+79951128880">+7(995) 112-88-80</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" src="./footer.less"></style>
