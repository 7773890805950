<template>
  <div class="services page__section_2">
    <div class="services__wrapper container">
      <h1 class="services__title">Цены на вскрытие замков</h1>
      <div class="services__buttons">
        <div class="services__buttons-item">
          <div
            class="services__btn"
            v-for="(service, index) in categories"
            :key="index"
          >
            <btn
              @click="selectMenu(index)"
              :class="{ btn_active: index == selected }"
            >
              {{ service.title }}
            </btn>
          </div>
        </div>
      </div>
      <div class="services__cards">
        <div
          class="service-card"
          v-for="(card, index) in categories[selected].cards"
          :key="index"
        >
          <div class="service-card__wrapper">
            <div class="service-card__image">
              <img
                :src="
                  require(`../../assets/images/img/product/${
                    card.img + '.jpg'
                  }`)
                "
                alt=""
                class="service-card__img"
              />
            </div>
            <div class="service-card__body">
              <p class="service-card__title">{{ card.title }}</p>
              <p class="service-card__text">От {{ card.price }} руб.</p>
              <div class="service-card__button" @click="addTextModal(card)">
                Подробнее
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal">
      <modal
        v-if="showModal"
        @close="showModal = !showModal"
        :text="this.doneText"
        :title="this.doneTitle"
      ></modal>
    </transition>
  </div>
</template>

<script>
import modal from "../modal/modal.vue";
import btn from "../btn/btn.vue";

export default {
  components: {
    modal,
    btn,
  },
  methods: {
    selectMenu(index) {
      this.selected = index;
    },
    addTextModal(card) {
      this.showModal = !this.showModal;
      this.doneText = card.text;
      this.doneTitle = card.title;
    },
  },
  data() {
    return {
      showModal: false,
      selected: 1,
      doneText: String,
      doneTitle: String,
      categories: [
        {
          title: "Сейфы",
          cards: [
            {
              title: "Вскрытие сейфа",
              price: 1500,
              text: "Вы не можете открыть сейфовый замок? Не стоит пытаться его открыть самостоятельно. Обратитесь в аварийную службу «Служба-вскрытия24». Профессиональные специалисты компании оперативно в течение 20-30 минут после вызова приезжают по указанному адресу в любом городе круглосуточно в любой день недели. Наши мастера применяют специальный инструмент, а это позволяет открывать даже самые сложные многоуровневые запоры без повреждений.",
              img: "8",
            },
            {
              title: "Ремонт сейфа",
              price: 1500,
              text: "Профессиональные специалисты компании оперативно отремонтируют Ваш сейф.",
              img: "9",
            },
          ],
        },
        {
          title: "Двери",
          cards: [
            {
              title: "Вскрытие дверных замков",
              price: 2000,
              text: "Не стоит пытаться решать проблему самостоятельно с использованием подручных средств и соседей, которые советуют открыть ее с помощью топора, молотка, дрели, болгарки или еще какого-либо подручного инструмента. Это не только испортит замок, но и гарантированно причинит вред самой двери",
              img: "1",
            },
            {
              title: "Ремонт замков",
              price: 800,
              text: "Экстренная служба круглосуточно оказывает услуги по вскрытию дверей во всех районах. Дежурная бригада приедет по указанному адресу не более чем через 30 минут после обращения.",
              img: "2",
            },
            {
              title: "Установка невидимого замка",
              price: 20000,
              text: "Последние несколько десятилетий особой популярностью пользуются замки невидимки. Свое название они получили за счет отсутствия скважины и сложности определения положения ригеля. Для злоумышленников данный тип замка — это сложная преграда. Вскрытие замка невидимки требует специальных знаний и умений, а обычный взлом при помощи болгарки может вызвать ненужный шум. Как правило, подобным замком можно управлять при помощи радиобрелка, мобильного телефона, сканера или даже наборного поля. Вместо ключей используются магнитные карты, отпечаток пальца или сетчатки глаза, ИК-устройства.",
              img: "3",
            },
            {
              title: "Установка домофона",
              price: 10000,
              text: "Домофон, в отличие от кодового замка, не только обеспечивает защиту от посещения подъезда непрошенными гостями, но и помогает связать посетителя у двери с хозяином дома без необходимости открывать дверь. Гость позвонил, хозяин узнал, кто пришел и зачем, и впустил или не впустил посетителя. Это устройство позволяет поддерживать порядок в подъезде и закрывает доступ нежелательным визитерам.",
              img: "4",
            },
            {
              title: "Установка дополнительного замка",
              price: 2000,
              text: "Вы хотите установить дополнительный замок ? Установка запорного механизма на входную или межкомнатную дверь дело достаточно сложное и требует определенной квалификации. Не рекомендуем заниматься этим самостоятельно. Обратитесь в сервисную службу «Служба-вскрытия24». Если требуется срочный монтаж, профессиональные специалисты приедут в любое время суток в любой район.",
              img: "5",
            },
            {
              title: "Замена личинки замка",
              price: 1200,
              text: "Не всегда, когда плохо работает замок, нужно менять его целиком. В некоторых случаях, если сам запорный механизм цел и нормально функционирует, достаточно будет произвести замену личинки замка. И тогда не нужно тратить много денег на покупку нового запорного устройства, и ломать себе голову, какую модель и конструкцию выбрать.",
              img: "6",
            },
            {
              title: "Замена замка входной двери",
              price: 2000,
              text: "Переезд, поломка старого замка, смена двери, потеря ключа, — все это может привести к тому, что вам срочно потребуется сменить дверной замок. Теоретически каждый может сделать это самостоятельно, но на практике повредить или сломать дверь или новую запорную систему при установке значительно легче, чем произвести замену правильно и быстро.",
              img: "7",
            },
          ],
        },
        {
          title: "Авто",
          cards: [
            {
              title: "Вскрытие автомобиля",
              price: 1500,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "10",
            },
            {
              title: "Копирование ключа с чипом",
              price: 2000,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "11",
            },
            {
              title: "Копирование чипа иммобилайзера",
              price: 1500,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "12",
            },
            {
              title: "Восстановление ключей при полной утере с иммобилайзером",
              price: 8000,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "13",
            },
            {
              title: "Восстановление ключей при полной утере(по замку)",
              price: 2500,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "14",
            },
            {
              title: "Прикурить авто ",
              price: 700,
              text: "По телефону можно узнать всю информацию, просто кликните по кнопке",
              img: "15",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style src="./services.less" lang="less"></style>
