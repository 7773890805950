<template>
  <div class="reviews page__section_4">
    <div class="reviews__wrapper container">
      <div>
        <div>
          <p class="reviews__title">Отзывы клиентов</p>
        </div>
        <swiper
          :slidesPerView="1"
          :spaceBetween="10"
          :slidesPerGroup="1"
          :loop="true"
          :navigation="true"
          :centeredSlides="true"
          :speed="1000"
          :breakpoints="{
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
              slidesPerGroup: 1,
            },
          }"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
        >
          <swiper-slide
            class="slide"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="slide-item">
              <div class="slide-item__image">
                <img
                  :src="
                    require(`@/assets/images/img/reviews/${item.img + '.png'}`)
                  "
                  alt=""
                  class="slide-item__img"
                />
              </div>
              <p class="slide-item__text">
                {{ item.text }}
              </p>
              <p class="slide-item__name">{{ item.name }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          img: "1",
          name: "Наталья Рузняк",
          text: "Недавно у меня случилась ужасная ситуация: пришла с работы, начинаю открывать дверь, а замок заклинило. Ключ не проходит, ничего никуда не вертится. Никогда до этого не сталкивалась с подобной проблемой. Пришлось вызывать специалистов. Приехали быстро. Ждала, наверное, минут 20 после вызова. Вскрыли замок. Правда пришлось потом его менять, но я хоть домой попала. Спасибо!",
        },
        {
          img: "4",
          name: "Владимир Белов",
          text: "Вышел выкинуть мусор на площадку, так дверь закрылась за мной, а открывать нечем. Впереди выходные, и настроение испорчено. Двери у меня стоят отличные, металлические и взломать их практически невозможно. Зашёл к соседям, так как они заказывали до этого услугу вскрытие сейфов, мне подсказали воспользоваться помощью данной экстренной службы. Вызвал и уже через полчаса был дома. Очень рад, оперативно сработали, профессионалы.",
        },
        {
          img: "3",
          name: "Виктория Котлова",
          text: "Переехала в новое место, понадобилось срочно сменить замки, были контакты мастерской. Вызвала мастера. Очень понравилось, что было сделано все аккуратно, постелили вниз пленку, бережно обращались с дверью. Мне установили два новых замка. При этом, от вызова мастера до конца работы прошло 5 часов, при этом мне не пришлось ездить за замками в магазин. Все было у специалиста с собой.",
        },
        {
          img: "5",
          name: "Алексей М",
          text: "Приехал из командировки, а зайти не могу. Дверь металлическую заклинило и шансы на успех были нулевые. Пытался открыть ее подручными средствами, но без успеха. Вспомнил, что так же раньше пользовался услугами Сергея. Набрал его телефон и вызвал слесарей. Смогли открыть дверь за несколько минут, замена замков не потребовалась. Работают хорошо, благодарю",
        },
        {
          img: "3",
          name: "Ирина Любинец",
          text: "Выбежала в магазин буквально на пять минут, а дочка 1 годик осталась одна в доме. Пришла назад, а ключей нету с собой. Думала сойду с ума. Дверь металлическая, а ребенок испугался моих криков и начал плакать. В данный момент была бы рада любой поддержке. Был с собой мобильный телефон. Нашла службу вскрытия замков. Сергей приехал очень быстро. Открыли двери в течение нескольких минут, мастер работает отлично. Спасибо огромное за помощь",
        },
        {
          img: "6",
          name: "Василий Иванов",
          text: "Потерял ключи от автомобиля, которые лежали в кармане, где-то в городе. Пробовал самостоятельно открыть своего соляриса, но безрезультатно. Нашел при подмоге мобильного интернета в телефоне компанию Вскрытия-замков.рф, позвонил и вызывал спасателя, парень приехал быстро, ожидать не пришлось. Уже через двадцать минут я завел автомобиль используя запасной комплект ключей, находящегося в салоне. Спасибо большое, оставил отзыв!",
        },
        {
          img: "7",
          name: "Дмитрий Андреев",
          text: "Захлопнулась дверь, а связка с ключами осталась на комоде, попасть в квартиру не было возможности. Думал уже вызывать спасателей МЧС и платить штраф, но вовремя подсказали воспользоваться услугами одной компании. Мужчина быстро открыл тяжелую железную дверь без потери работоспособности замка. Оперативно сработал и взял недорого.",
        },
      ],
    };
  },
};
</script>

<style lang="less" src="./reviews.less"></style>
