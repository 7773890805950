<template>
  <div class="benefits page__section_6">
    <div class="benefits__wrapper container">
      <p class="benefits__title">Наши Преимущества</p>
      <div class="benefits__cards">
        <div class="benefits-card" v-for="(card, index) in cards" :key="index">
          <div class="benefits-card__item">
            <div class="benefits-card__image">
              <img
                :src="
                  require(`@/assets/images/ico/benefits/${card.ico + '.svg'}`)
                "
                alt=""
                class="benefits-card__svg"
              />
            </div>
            <p class="benefits-card__title">{{ card.title }}</p>
            <p class="benefits-card__text">
              {{ card.text }}
            </p>
          </div>
        </div>
      </div>
      <div class="benefits__buttons">
        <a class="button" href="tel:+79951128880">Вызвать мастера</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          ico: "1",
          title: "Качество",
          text: "Вскрытие без повреждений, максимально аккуратная работа.",
        },
        {
          ico: "2",
          title: "Срочный выезд",
          text: "Выезд в течении 10 минут после обращения!",
        },
        {
          ico: "3",
          title: "Гарантия",
          text: "Лучшие монтажники, с опытом работы более 10 лет.",
        },
        {
          ico: "4",
          title: "Вскрываем замок 24 часа в сутки",
          text: "Приезжаем, чтобы спасти вас из сложной ситуации. Без перерывов и выходных.",
        },
        {
          ico: "5",
          title: "Вскрываем замки за 1 час",
          text: "Это для самых сложных моделей. На простую уйдет 10 минут.",
        },
        {
          ico: "6",
          title: "Вызов принят, выезжаем",
          text: "Выезжаем по всей России.",
        },
      ],
    };
  },
};
</script>

<style lang="less" src="./benefits.less"></style>
