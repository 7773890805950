<template>
  <div class="app"></div>
  <MainPage />
</template>

<script>
import MainPage from "@/pages/main-page/mainPage.vue";

export default {
  name: "App",
  components: {
    MainPage,
  },
};
</script>

<style lang="less">
.app {
  overflow: hidden;
}
</style>
