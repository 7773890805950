<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <p class="modal-title">{{ title }}</p>
          <p class="modal-text">{{ text }}</p>
          <a class="modal-link" v-if="title" href="tel:+79951128880"
            >Позвонить мастеру</a
          >
          <button class="modal-default-button" @click="$emit('close')"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    title: String,
  },
};
</script>

<style src="./modal.less" lang="less"></style>
